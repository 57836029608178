import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(@Inject(DOCUMENT) readonly document: Document) {}
  /** The Window object from Document defaultView */
  get window(): Window {
    return this.document.defaultView!;
  }

  public redirect(url: string, target = '_self'): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(!!this.window.open(url, target));
      } catch (e) {
        reject(e);
      }
    });
  }
}
